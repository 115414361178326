<template lang="pug">
h2(v-if='lessThan("tablet")')
	| {{ variablesList.reviewsGroupTitle }}
	|
	span(class='Accent') {{ variablesList.reviewsGroupAccentTitle }}
h2(v-if='greaterThan("mobileWide")', class='text-center short-text') 
	| {{ variablesList.reviewsGroupTitle }}
	|
	span(class='Accent') {{ variablesList.reviewsGroupAccentTitle }}
	|
	| {{ variablesList.reviewsGroupSubtitle }}

SliderReviewsGroups(:groups='reviewsGroupsState')
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
const { variablesList, fetchVariables } = useVariables();
const { reviewsGroupsState, fetchReviewsGroups } = useReviews();

/**
 * Define fetch
 */
await fetchVariables();
await fetchReviewsGroups();
</script>
