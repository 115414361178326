<template lang="pug">
div(class='ReviewsGroup')
	template(v-if='greaterThan("tablet")')
		div(v-for='group in groups', :key='group.id', class='ReviewsGroup--Item')
			div(class='Name') {{ group.title }}
			UiButton(
				:to='group.link',
				:size='greaterThan("mobileWide") ? "md" : "sm"',
				target='_blank',
				variant='secondary',
				iconRight='system/arrow-top-right',
				:iconRightSize='greaterThan("mobileWide") ? "sm" : "xs"',
				rounded
			) Перейти
			UiIcon(:name='`reviews/${group.icon}`', size='custom', class='Icon', :class='group.icon')
	template(v-else)
		UiCarousel(
			class='ReviewsGroup--Carousel',
			:perView='greaterThan("mobileWide") ? 2.2 : greaterThan("mobileMedium") ? 1.6 : 1.2',
			ref='slider'
		)
			template(#default='{ carouselItemProps }')
				TransitionGroup(name='slider')
					UiCarouselItem(v-for='group in groups', :key='group.id', v-bind='carouselItemProps')
						div(class='ReviewsGroup--Item')
							div(class='Name') {{ group.title }}
							UiButton(
								:to='group.link',
								:size='greaterThan("mobileWide") ? "md" : "sm"',
								target='_blank',
								variant='secondary',
								iconRight='system/arrow-top-right',
								:iconRightSize='greaterThan("mobileWide") ? "sm" : "xs"',
								rounded
							) Перейти
							UiIcon(:name='`reviews/${group.icon}`', size='custom', class='Icon', :class='group.icon')
</template>

<script lang="ts" setup>
import { ReviewsGroup } from '~/models/reviews-group';

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();

/**
 * Define props
 */
const { groups = [] } = defineProps<{
	groups: ReviewsGroup.Model[];
}>();
</script>

<style lang="less" scoped>
.ReviewsGroup {
	padding: 1.25rem 0 0 0;
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&--Carousel {
		.grid(1 span, 1 span);
		.box(block; 100%; auto; none);
	}

	&--Item {
		padding: 2rem;
		.grid(1 span);
		.box(grid; 100%; 100%; fade(@ColorBase, 5%); 3rem; auto; 1fr 1fr; end; start);
		.border-radius(@BorderRadiusMedium);
		& > .Name {
			.grid(1 2; 1 3);
			.text(@ColorBase; 1.25em 1.125rem @semibold);
		}
		& > .UiButton {
			.grid(2 3; 1 2);
		}
		& > .Icon {
			.grid(2 3; 2 3);
			.text(@ColorWhite);
			&.vk {
				.box(block; 100%; 2.8125rem);
			}
			&.zoon {
				.box(block; 100%; 2.8125rem);
			}
			&.yandex {
				.box(block; 100%; 4.5625rem);
			}
			&.yell {
				.box(block; 100%; 4rem);
			}
			&.twogis {
				.box(block; 100%; 4.5rem);
			}
			&.youtube {
				.box(block; 100%; 3.5rem);
			}
		}
	}

	@media all and (min-width: @mobile) and (max-width: (@tablet - 1px)) {
		&--Item {
			& > .Name {
				max-width: 12rem;
				.text(@ColorBase; 1.25em 1.25rem @semibold);
			}
		}
	}

	@media all and (min-width: @tablet) and (max-width: (@laptop - 1px)) {
		&--Item {
			& > .Name {
				max-width: 13rem;
				.text(@ColorBase; 1.25em 1.375rem @semibold);
			}
		}
	}

	@media all and (min-width: @laptop) {
		padding: 3rem 0 0 0;
		.box(grid; 100%; auto; none; 1rem; auto; repeat(3, 1fr); center; start);

		&--Item {
			& > .Name {
				max-width: 14rem;
				.text(@ColorBase; 1.25em 1.5rem @semibold);
			}
		}
	}
}
</style>
